<!--能源模块--设备列表显示页-->
<template>
  <div id="equipmentList">
    <div class="header">
      <div class="header-header">
        <span>{{ $t('energyEquipmentList.a1') }}</span>        
      </div>
      <a-icon
        type="close"
        :style="{ fontSize: '20px', color: '#7682CE' }"
        @click="goDack"
      />
    </div>
    <div class="search">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <div class="form_row">
          <div class="form_col">
            <a-form-model-item :label="$t('energyEquipmentList.a2')">
              <a-input v-model="form.name" />
            </a-form-model-item>
          </div>          
          <div class="form_col">
            <a-form-model-item>
              <a-button type="primary" @click="inquire">{{$t('energyEquipmentList.a5')}}</a-button>
            </a-form-model-item>
          </div>          
        </div>        
      </a-form-model>
    </div>
    <div class="main">
      <my-tabletitle>{{ $t('energyEquipmentList.a6') }}</my-tabletitle>
      <my-table :data="equipmentList" :loading="loading"></my-table>
    </div>
    <div class="foor"></div>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import Table from "./components/equipmentTable";
import { getEnePointGrDBList,getEnePointRelationDBList,getTopologyList,getEnergyDataTreeCalculate} from "../../api/energy";
import moment from "moment";

export default {
  data() {
    return {
      endOpen: false,
      form: {
        name: "",
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
      },
      equipmentList: undefined,
      loading: false,
      startDate: undefined,
      rules: {
        date1: [
          { required: true, message: "$t('energyEquipmentList.a7')", trigger: "change" },
        ],
        date2: [
          { required: true, message: "$t('energyEquipmentList.a7')", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    moment,
    // 页面后退
    goDack() {
      this.$router.go(-1);
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      this.startDate = moment(value._d).format("YYYY-MM-DD HH:mm:ss");
      this.form.date2 = null;
    },
    // 条件查询方法
    inquire() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          // let par={
          //   siteId:this.$route.query.id,
          //   depth:0,
          // }
          let par={
            siteId:this.$route.query.id,
            // rootId:18,
          }
          getEnergyDataTreeCalculate(par)
          // getTopologyList(par)
          // getEnePointRelationDBList(par)
          // getEnePointGrDBList(par)
            .then((res) => {
              this.loading = false;
              let { data } = res;
              // console.log("getEnePointRelationDBList res:", res);
              // console.log("getTopologyList res:", res);
              console.log("getEnergyDataTreeCalculate res:", res);
              // console.log("getEnePointGrDBList res:", res);               
              // this.equipmentList = data;
              // for(let i=0;i<this.equipmentList.length;i++){
              //   this.equipmentList[i].key = i+1;
              // }
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    // 初始日期/时间的日期不可选范围
    disabledStartDate(startValue) {
      const endValue = this.form.date2;
      if (!startValue || !endValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        startValue >= moment().endOf()
      );
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startValue = this.form.date1;
      let startDate = this.startDate;
      if (!endValue || !startValue) {
        return false;
      }
      return (
        startValue.valueOf() >= endValue.valueOf() ||
        endValue >= moment().endOf() ||
        endValue >= moment(new Date(startDate)).add(1, "months")
      );
    },
    // 初始日期/时间选择弹出日历和关闭日历的回调
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    // 终止日期/时间选择弹出日历和关闭日历的回调
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-table": Table,
  },
};
</script>

<style scoped>
#equipmentList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #cccdcd;
  white-space: nowrap;
  overflow: hidden;
}
.header-header {
  display: flex;
  align-items: center;
}
.header-header span {
  margin-right: 30px;
}
.search {
  padding: 0 20px;
  overflow: auto;
}
.main {
  width: 100%;
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
button {
  width: 120px;
}
</style>