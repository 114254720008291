<template>
  <div>
    <a-table
      :columns="columnsR"
      :dataSource="data"
      :scroll="{ x: 1800 }"
      :pagination="pagination"
      :loading="loading"
    >
      <template slot="operate" slot-scope="text, record">
        <div>
          <a-popover placement="left">
            <template slot="content">
              <a-button type="primary">{{$t('operaterecords.a8')}}</a-button>
              <a-button type="primary" @click="() => remarks(record)"
                >{{$t('operaterecords.a13')}}</a-button
              >
            </template>
            <a>{{$t('operaterecords.a14')}}</a>
          </a-popover>
        </div>
      </template>
    </a-table>
    <a-modal
      title="$t('operaterecords.b8')"
      centered
      :visible="visible"
      :closable="false"
      :ok-button-props="confirm_forbidden"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-textarea
        v-model="value"
        placeholder="$t('operaterecords.a15')"
        :auto-size="{ minRows: 10, maxRows: 10 }"
      />
    </a-modal>
  </div>
</template>

<script>
import { remarks } from "../../../api/api";

export default {
  name: "alarmtable",
  props: {
    data: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      visible: false,
      value: "",
      params: "",
      confirm_forbidden: { props: { disabled: true } },
      confirmLoading: false,
      pagination: {
        total: 0, //数据总数
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["10", "50", "100"], //每页中显示的数据
        showTotal: (total) => ` ${total} ${this.$t('warningRecord.a35')}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
      },   

      columns: [
        {
          title: "NO",
          dataIndex: "id",
          width: 60,
          ellipsis: true,
          // scopedSlots: { customRender: "NO" },
          fixed: "left",
        },
        {
          title: this.$t('energyEquipmentList.b1'),
          dataIndex: "grsOwnerId",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b2'),
          dataIndex: "name",
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b3'),
          dataIndex: "title",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b4'),
          dataIndex: "description",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b5'),
          dataIndex: "groupType",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b6'),
          dataIndex: "parentId",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b7'),
          dataIndex: "hierarchyDepth",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b8'),
          dataIndex: "nodePath",
          width: 150,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b9'),
          dataIndex: "iconNo",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b10'),
          dataIndex: "grUsageType",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b11'),
          dataIndex: "pointGrCode",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b12'),
          dataIndex: "operate",
          scopedSlots: { customRender: "operate" },
          width: 100,
          fixed: "right",
        },
      ],
      columnsR: [
        {
          title: "NO",
          dataIndex: "id",
          width: 60,
          ellipsis: true,
          fixed: "left",
        },
        {
          title: this.$t('energyEquipmentList.b1'),
          dataIndex: "siteId",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b2'),
          dataIndex: "name",
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b4'),
          dataIndex: "description",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b5'),
          dataIndex: "chartType",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b6'),
          dataIndex: "parentId",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b7'),
          dataIndex: "hierarchyDepth",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b8'),
          dataIndex: "nodePath",
          width: 150,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b9'),
          dataIndex: "iconNo",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b11'),
          dataIndex: "energyType",
          width: 60,
          ellipsis: true,
        },
        {
          title: this.$t('energyEquipmentList.b10'),
          dataIndex: "grUsageType",
          width: 60,
          ellipsis: true,
        },        
        {
          title: this.$t('energyEquipmentList.b12'),
          dataIndex: "operate",
          scopedSlots: { customRender: "operate" },
          width: 100,
          fixed: "right",
        },
      ],
    };
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal !== "") {
          this.confirm_forbidden.props.disabled = false;
        } else {
          this.confirm_forbidden.props.disabled = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    remarks(key) {
      this.visible = true;
      this.params = key.PARAMS;
      console.log(key);
    },
    handleOk(e) {
      this.confirmLoading = true;
      let data = new FormData();
      data.append("params", this.params);
      data.append("comment", this.value);
      remarks(data)
        .then((res) => {
          this.confirmLoading = false;
          this.visible = false;
          this.$parent.onSubmit();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
button {
  margin-right: 10px;
}
</style>